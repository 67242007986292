codenteam-report-header .titleRunning {
  position: running(titleRunning);
}

:root,
.pagedjs_page,
.pagedjs_pagebox {
  --pagedjs-margin-right: 0 !important;
  --pagedjs-margin-left: 0 !important;
  --pagedjs-margin-top: 1.7in !important;
  --pagedjs-margin-bottom: 0.2in !important;
}

.pagedjs_pagebox {
  grid-template-columns: [left] 0 [center] 210mm [right] 0 !important;
}

.pagedjs_page {
  margin: 0 auto;
}

.titleRunning {
  position: running(titleRunning);
}

.runningTest {
  position: running(runningTest);
}

/** Keep those before body **/
.top-center {
  position: running(topCenter);
}

@media print {
  @page {
    margin: 0;
    size: A4;

    @top-center {
      align-items: baseline;
      content: element(topCenter);
    }

    @bottom-center {
      content: 'Page: ' counter(page) ' of ' counter(pages);
      font-size: 1rem;
      color: black;
    }
  }

  .test-test-test {
    color: white;
  }

  .back {
    background-color: white !important;
  }

  .page-number::after {
    display: block !important;
  }
}

.pagedjs_page_content .report-table tr:first-child td:first-child {
  border-top-left-radius: 0.375rem;
}
.pagedjs_page_content .report-table tr:first-child td:last-child {
  border-top-right-radius: 0.375rem;
}
.pagedjs_page_content .single-scan-result:first-child {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.pagedjs_page_content .single-scan-result:last-child {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
